const assetsReducer = (state = { assetsList: [], selectedAsset: null, assetsLogs: [] }, action) => {
  switch (action.type) {
    case "GET_ASSETS_LIST_SUCCESS":
      return { ...state, assetsList: action.payload }
    case "GET_ASSET_DETAILS_SUCCESS":
      return { ...state, selectedAsset: action.payload }
    case "GET_ASSETS_LOGS_SUCCESS":
      return { ...state, assetsLogs: action.payload }
    default:
      return state
  }
}

export default assetsReducer
